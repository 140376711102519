var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-divider',{staticClass:"divider"},[_vm._v(_vm._s(_vm.group.name))]),_c('a-row',{attrs:{"type":"flex","justify":"space-around","gutter":[8, 8]}},_vm._l((_vm.group.payment_methods),function(method){return _c('a-card',{key:method.id,class:['card', method.available ? 'card-active' : ''],attrs:{"hoverable":true,"body-style":{
				padding: '16px',
				height: '100%',
				display: 'flex',
			}},on:{"click":function($event){return _vm.$emit('click', {
					groupId: _vm.group.id,
					methodId: method.id,
					available: !method.available,
				})}}},[_c('div',{staticClass:"body"},[_c('img',{staticClass:"image",attrs:{"src":method.image}})])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }