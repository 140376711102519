<template>
	<a-form :form="form" class="form" @submit.prevent="handleSubmit">
		<a-row :gutter="8">
			<a-col :span="5">
				<a-form-item label="Data inicial">
					<a-date-picker
						v-decorator="[
							'start-date',
							{
								initialValue: closing.start_date,
								rules: [
									{
										type: 'object',
										required: true,
										message: 'Informe a data inicial',
									},
								],
							},
						]"
						:disabled-date="disableStartDate"
						placeholder="Selecione data"
						format="DD/MM/YYYY"
						:locale="locale"
						@change="onStartDateChange"
					/>
				</a-form-item>
			</a-col>
			<a-col :span="5">
				<a-form-item label="Hora inicial">
					<a-time-picker
						v-decorator="[
							'start-hour',
							{
								initialValue: closing.start_hour,
								rules: [
									{
										type: 'object',
										required: true,
										message: 'Informe a hora inicial',
									},
									{
										validator: onValidateStartHour,
									},
								],
							},
						]"
						format="HH:mm"
						placeholder="Selecione hora"
						:disabled="disableFinalDateField"
						style="width: 140px;"
						@change="onStartHourChange"
					/>
				</a-form-item>
			</a-col>
			<a-col :span="5">
				<a-form-item label="Data final">
					<a-date-picker
						v-decorator="[
							'final-date',
							{
								initialValue: closing.final_date,
								rules: [
									{
										type: 'object',
										required: true,
										message: 'Informe a data final',
									},
								],
							},
						]"
						:disabled-date="disableFinalDate"
						format="DD/MM/YYYY"
						:locale="locale"
						placeholder="Selecione data"
						:disabled="disableFinalDateField"
						@change="onFinalDateChange"
					/>
				</a-form-item>
			</a-col>
			<a-col :span="5">
				<a-form-item label="Hora final">
					<a-time-picker
						v-decorator="[
							'final-hour',
							{
								initialValue: closing.final_hour,
								rules: [
									{
										type: 'object',
										required: true,
										message: 'Informe a hora final',
									},
									{
										validator: onValidateFinalHour,
									},
								],
							},
						]"
						format="HH:mm"
						placeholder="Selecione hora"
						style="width: 140px;"
						:disabled="disableFinalDateField"
					/>
				</a-form-item>
			</a-col>
		</a-row>
		<a-form-item label="Mensagem">
			<div>
				<a-textarea
					v-decorator="[
						'message',
						{
							initialValue: closing.message,
							rules: [
								{
									required: true,
									message:
										'Digite uma mensagem para seus clientes',
								},
							],
						},
					]"
					placeholder="Digite uma mensagem que será exibida aos seus clientes"
					auto-size
				/>
			</div>
		</a-form-item>
		<a-form-item>
			<a-row type="flex" justify="center" :gutter="8">
				<a-col v-if="editing">
					<a-button @click="clearEditing">
						Cancelar
					</a-button>
				</a-col>
				<a-col>
					<a-button
						type="primary"
						html-type="submit"
						:icon="editing ? 'edit' : 'plus'"
						:loading="loading"
					>
						{{ editing ? 'Editar ' : 'Adicionar ' }} fechamento
					</a-button>
				</a-col>
			</a-row>
		</a-form-item>
	</a-form>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/pt_BR'
import { capitalizeFirstLetter } from '@/utils/utils'

export default {
	name: 'ScheduledClosingForm',
	data() {
		return {
			loading: false,
			editing: false,
			closing: {
				start_date: '',
				start_hour: '',
				final_date: '',
				final_hour: '',
				message: '',
			},
			disableFinalDateField: true,
			locale,
		}
	},
	beforeCreate() {
		this.form = this.$form.createForm(this, {
			name: 'time_related_controls',
		})
	},
	methods: {
		disableStartDate(current) {
			return current < this.moment().startOf('day')
		},
		disableFinalDate(current) {
			return current < this.closing.start_date
		},
		onStartDateChange(date) {
			this.closing.start_date = date
			this.closing.start_hour = this.moment()
				.add(1, 'hours')
				.startOf('hour')
			this.closing.final_date = date
			this.closing.final_hour = this.moment('23:59', 'HH:mm')
			this.disableFinalDateField = false
		},
		onFinalDateChange(date) {
			this.closing.final_date = date
		},
		onStartHourChange(hour) {
			this.closing.start_hour = hour
		},
		handleSubmit() {
			this.form.validateFields((err, values) => {
				if (err) return

				const {
					start_date,
					start_hour,
					final_date,
					final_hour,
				} = this.closing

				const start = `${start_date.format(
					'YYYY-MM-DD'
				)}T${start_hour.format('HH:mm')}`
				const end = `${final_date.format(
					'YYYY-MM-DD'
				)}T${final_hour.format('HH:mm')}`

				const payload = {
					closed_from: new Date(start),
					closed_until: new Date(end),
					reason: capitalizeFirstLetter(values['message']),
				}

				if (this.editing) {
					this.$emit('edit', {
						id: this.closing.id,
						data: payload,
					})
					this.clearEditing()
				} else this.$emit('save', payload)
			})
		},
		onValidateFinalHour(rule, value, callback) {
			const { start_date, final_date, start_hour } = this.closing

			if (final_date.diff(start_date, 'days') > 0) {
				callback()
			} else if (value.format('HH:mm') > start_hour.format('HH:mm')) {
				callback()
			} else {
				callback('Hora anterior à hora inicial')
			}
		},
		onValidateStartHour(rule, value, callback) {
			if (this.moment().add(1, 'hours').startOf('hour') <= value) {
				callback()
			} else {
				callback('Somente a partir da próxima hora')
			}
		},
		setEditClosing(closing) {
			const start = this.moment(closing.closed_from)
			const end = this.moment(closing.closed_until)

			this.closing.start_date = start
			this.closing.start_hour = start
			this.closing.final_date = end
			this.closing.final_hour = end
			this.closing.message = closing.reason
			this.closing.id = closing.id

			this.disableFinalDateField = false
			this.editing = true

			this.form = this.$form.createForm(this, {
				name: 'time_related_controls',
			})
		},
		clearEditing() {
			this.closing.start_date = ''
			this.closing.start_hour = ''
			this.closing.final_date = ''
			this.closing.final_hour = ''
			this.closing.message = ''
			this.disableFinalDateField = true
			this.editing = false

			this.form = this.$form.createForm(this, {
				name: 'time_related_controls',
			})
		},
	},
}
</script>

<style lang="less" scoped>
.form {
	padding: 0 16px;
}
</style>
