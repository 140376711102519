<template>
	<div class="container">
		<a-divider class="divider">{{ group.name }}</a-divider>
		<a-row type="flex" justify="space-around" :gutter="[8, 8]">
			<a-card
				v-for="method in group.payment_methods"
				:key="method.id"
				:hoverable="true"
				:class="['card', method.available ? 'card-active' : '']"
				:body-style="{
					padding: '16px',
					height: '100%',
					display: 'flex',
				}"
				@click="
					$emit('click', {
						groupId: group.id,
						methodId: method.id,
						available: !method.available,
					})
				"
			>
				<div class="body">
					<img :src="method.image" class="image" />
				</div>
			</a-card>
		</a-row>
	</div>
</template>

<script>
export default {
	name: 'ToggleMethodsList',
	props: {
		group: {
			type: Object,
			required: true,
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	margin: 0;
	padding: 0;
	width: 100%;

	.divider {
		margin-top: 0;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.55);
	}

	.card {
		margin: 0 8px 8px 0px;
		width: 80px;
		height: 80px;
		border-radius: 5px;

		.body {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.image {
				width: 100%;
			}
		}
	}

	.card-active {
		border-color: #f57d31;
		background-color: #fff7f0;
	}
}
</style>
