<template>
	<a-card size="small" class="card" :body-style="{ padding: '16px 0px' }">
		<div slot="title" style="font-weight: normal;">
			Fechamentos configurados
			<a-badge
				:count="closesCount"
				:number-style="{
					backgroundColor: '#e8e8e8',
					color: '#000',
				}"
				style="margin: 0px 8px 5px;"
				:show-zero="true"
				:overflow-count="999"
			/>
		</div>
		<ScheduledClosingForm
			ref="scheduledClosingForm"
			@save="onSaveClosing"
			@edit="onEditClosing"
		/>
		<a-divider dashed />
		<div style="padding: 0px 16px;">
			<a-list
				v-if="loading"
				item-layout="horizontal"
				:data-source="[1, 2, 3]"
			>
				<a-list-item
					slot="renderItem"
					key="item.title"
					slot-scope="item"
				>
					<a-skeleton
						:loading="true"
						:title="false"
						:paragraph="{ rows: 2, width: ['100%', '65%'] }"
						active
						avatar
					>
						{{ item }}
					</a-skeleton>
				</a-list-item>
			</a-list>
			<a-list
				v-else
				item-layout="horizontal"
				:data-source="scheduleds"
				:locale="ptBR"
			>
				<a-list-item
					slot="renderItem"
					slot-scope="item"
					style="padding: 8px 0;"
				>
					<ActionsButtons
						slot="actions"
						:duplicate="false"
						:slide="false"
						description="fechamento"
						style="color: rgba(0, 0, 0, 0.7);"
						@delete="closingDelete(item.id)"
						@edit="closingEdit(item)"
					/>
					<a-list-item-meta v-if="item" :description="item.reason">
						<div slot="title">
							Fechado de
							<a-tag
								color="rgb(135, 208, 104"
								style="margin-right: 0;"
							>
								{{
									moment(item.closed_from).format(
										'DD/MM/YYYY'
									)
								}}
								às
								{{ moment(item.closed_from).format('HH:mm') }}
							</a-tag>
							até
							<a-tag color="rgb(135, 208, 104">
								{{
									moment(item.closed_until).format(
										'DD/MM/YYYY'
									)
								}}
								às
								{{ moment(item.closed_until).format('HH:mm') }}
							</a-tag>
						</div>
						<a-avatar slot="avatar" icon="calendar" :size="40" />
					</a-list-item-meta>
				</a-list-item>
			</a-list>
		</div>
	</a-card>
</template>

<script>
import ScheduledClosingForm from './scheduledClosingForm'
import { mapActions, mapGetters } from 'vuex'
import { merchantService } from '../../../services'
import { ActionsButtons } from '@/modules/partner/components'

export default {
	name: 'ScheduledClosing',
	components: {
		ScheduledClosingForm,
		ActionsButtons,
	},
	data() {
		return {
			loading: false,
			scheduleds: [],
			ptBR: { emptyText: 'Nenhum fechamento programado configurado' },
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		closesCount: function () {
			return this.scheduleds.length
		},
	},
	async beforeMount() {
		await this.getData()
	},
	methods: {
		...mapActions('alert', ['error']),
		async getData() {
			try {
				this.loading = true
				this.scheduleds = await merchantService.getClosingScheduleds(
					this.selectedMerchantId
				)
			} catch (error) {
				this.error(error)
			} finally {
				this.loading = false
			}
		},
		async onSaveClosing(data) {
			try {
				this.loading = true
				const payload = {
					merchantId: this.selectedMerchantId,
					data,
				}
				await merchantService.addClosingScheduled(payload)
				await this.getData()
			} catch (error) {
				this.error(error)
			} finally {
				this.loading = false
			}
		},
		async onEditClosing(values) {
			try {
				this.loading = true
				const payload = {
					merchantId: this.selectedMerchantId,
					closingId: values.id,
					data: values.data,
				}
				await merchantService.editClosingScheduled(payload)
				await this.getData()
			} catch (error) {
				this.error(error)
			} finally {
				this.loading = false
			}
		},
		async closingDelete(closingId) {
			try {
				this.loading = true
				const payload = {
					merchantId: this.selectedMerchantId,
					closingId,
				}
				await merchantService.deleteClosingScheduled(payload)
				await this.getData()
			} catch (error) {
				this.error(error)
			} finally {
				this.loading = false
			}
		},
		closingEdit(closing) {
			this.$refs.scheduledClosingForm.setEditClosing(closing)
		},
	},
}
</script>

<style lang="less" scoped>
.card {
	border-radius: 5px;
	margin-bottom: 24px;
}
</style>
